.card__avatarsBackground {
  background: url('/assets/img/layout/Background.png') center no-repeat;
  background-size: cover !important;
}

.card__background {
  background: url("/assets/img/layout/cardBackroundDesktop.png") center no-repeat;
  background-size: cover;
}

.specCard {
  display: flex;
  flex-direction: row;
  background: #111111;
  border-radius: 32px;
  margin: 60px 0 10px 0;
  height: 400px;

  .card__specLeftSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 60px;
    padding-left: 60px;
    width: 50%;
  }

  .card__img {
    width: 50%;
    height: 100%;
  }

  .card__leftSectionFooter {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  }

  .card__specContent {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;
    opacity: 0.5;

    p {
      margin: 0;
    }
  }


  .card__specHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    color: #FFFFFF;
    margin: 0;
    display: flex;
    align-items: center;
  }


}

.card {
  display: flex;
  flex-direction: row;
  background: #111111;
  border-radius: 32px;
  margin: 60px 0 20px 0;

  .card__leftSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 60px;
    padding-left: 60px;
    width: 40%;

  }

  .card__img {
    width: 60%;
    height: 100%;
  }

  .card__leftSectionFooter {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 20px;
  }


  .card__header {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 60px;
    color: #FFFFFF;
    margin: 0;
    display: flex;
    align-items: center;
    width: 520px;
  }

  .card__content {
    max-width: 520px;
    min-height: 152px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    color: #FFFFFF;
    opacity: 0.56;
    margin-bottom: 36px;
  }
}

@media screen and (max-width: 1300px) {
  .card {
    .card__leftSection {
      padding-left: 30px;
      width: 40%;

    }

    .card__img {
      width: 60%;
    }
  }
  .specCard {
    .card__specLeftSection {
      padding-left: 30px;
      width: 55%;
    }

    .card__img {
      width: 45%;
    }
  }
}

@media screen and (max-width: 1024px) {


  .specCard {
    height: 400px;
    max-width: 100% !important;
    margin: 0 0 20px 0 !important;

    .card__specHeader {
      width: 270px;
      height: 78px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: #FFFFFF;
    }

    .card__specHeader {
      width: 297px;
      height: 78px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: #FFFFFF;
    }
  }
  .card {
    max-width: 100% !important;
    height: 565px !important;
    margin: 0 0 0px 0 !important;


    .card__leftSection {
      margin-top: 35px;
      padding-left: 20px;
      width: 100%;
    }

    .card__img {
      width: 0%;
    }

    .card__header {
      width: 387px;
      height: 78px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;

      color: #FFFFFF;
    }

    .card__content {
      width: 387px;
      height: 231px;

      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 130%;
      color: #FFFFFF;
      opacity: 0.5;
    }

  }
}

@media screen and (max-width: 1023px) {
  .card {
    .card__background {
      background: url("/assets/img/layout/cardBackroundMedium.png") center no-repeat;
    }
  }
}


@media screen and (max-width: 768px) {
  .specCard {
    height: 350px;

    .card__header {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 39px;
      color: #FFFFFF;
    }

    .card__content {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 130%;
      color: #FFFFFF;
      opacity: 0.5;
    }

    .card__specLeftSection {
      width: 60% !important;
      margin-top: 30px;
    }
  }
  .card {

    max-width: 100% !important;
    margin: 20px 0 !important;
    height: 565px !important;


  }

}


@media screen and (max-width: 767px) {
  .card__avatarsBackground {
    background: url('/assets/img/layout/backgoundMobileAvatars.webp') center no-repeat;
    background-size: cover;
  }
  .card {
    max-width: 100% !important;
    margin: 0px 0px !important;


    .card__header {
      max-width: 271px;
      height: 64px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      color: #FFFFFF;
    }

    .card__content {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 130%;
      color: #FFFFFF;
      opacity: 0.5;
      width: 271px;
      height: 315px;
    }

    .card__leftSection {
      width: 100%;
    }

  }
  .specCard {
    .card__specHeader {
      width: 271px;
      height: 64px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      color: #FFFFFF;

    }
  }

}

@media screen and (max-width: 450px) {
  .card {
    .card__background {
      background: url('/assets/img/layout/CardBackgroundMobile.webp') center no-repeat;

    }
  }
}

@media screen and (max-width: 375px) {

  .card__content {
    width: 271px;
    margin-bottom: 60px !important;
  }

}