.card {
  height: 183px;
  width: 33%;

  &:not(:last-child) {

    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
}

.card__img {
  margin-left: 32px;
  width: 40px;
  height: 40px;
}

.card__headerContent {

}

.card__header {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #FFFFFF;
  margin: 16px 0 16px 32px;
}

.card__content {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: #FFFFFF;
  opacity: 0.6;
  margin: 0 0 0 32px;
  width: 80%;
}

@media screen and (max-width: 1023px) {
  .card {
    height: 183px;
    width: 90%;

    &:not(:last-child) {
      margin-top: 20px;
      border-right: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    }
  }
  .card__img {
    margin-left: 0;
    width: 28px;
    height: 28px;
  }
  .card__headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .card__header {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: 12px;
  }
  .card__content {
    margin-left: 0;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    opacity: 0.6;
    width: 94%;
  }
}

@media screen and (max-width: 1023px) {
  .card {
    height: 183px;
    width: 90%;

    &:not(:last-child) {
      margin-top: 20px;
      border-right: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    }
  }

  .card__headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .card__header {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: 12px;
    margin-top: 3px;
  }
  .card__content {

    width: 100%;
  }
}