.sidenav {
  height: 100%;
  width: 3%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: #090909;
  overflow-x: hidden;
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.sidenav__content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sidenav__linkDefault {
  display: block;
  cursor: pointer;
}

.sidenav__link {
  display: none;

}

.sidenav__tooltip {
  display: none;
  position: fixed;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #FFFFFF;
  padding: 5px 12px;
  background: rgba(9, 9, 9, 0.8);
  backdrop-filter: blur(8px);
  border-radius: 20px;

  //&:hover {
  //  display: flex !important;
  //  left: 30px;
  //}

}

.sidenav__tooltipActive {
  display: none;
}

.sidenav__linkDisabled {
  &:hover {
    .sidenav__tooltip {
      display: flex !important;
      left: 30px;
    }

    cursor: pointer;

    svg {
      fill-opacity: 1;

      g {
        opacity: 1;
      }

      path {
        fill: #FFFFFF;
        fill-opacity: 1;
      }

    }
  }

  //.sidenav__link {
  //
  //  display: block;
  //
  //  svg {
  //    fill-opacity: 0.5;
  //
  //    g {
  //      opacity: 0.5;
  //    }
  //
  //    path {
  //      fill: #FFFFFF;
  //      fill-opacity: 1;
  //    }
  //
  //  }
  //}

  .sidenav__linkDefault {
    display: block;
  }

  svg {
    fill-opacity: 0.5;

    g {
      opacity: 0.5;
    }

    path {
      fill: #FFFFFF;
      fill-opacity: 0.5;
    }

  }
}

.sidenav__linkActive {
  &:hover {
    .sidenav__tooltip {
      display: flex !important;
      left: 30px;
    }

    cursor: pointer;

    svg {
      fill-opacity: 1;

      g {
        opacity: 1;
      }

      path {
        fill: #FFFFFF;
        fill-opacity: 1;
      }

    }
  }

  .sidenav__link {

    display: block;

    svg {
      fill-opacity: 0.5;

      g {
        opacity: 0.5;
      }

      path {
        fill: #FFFFFF;
        fill-opacity: 1;
      }

    }
  }

  .sidenav__linkDefault {
    display: none;
  }

  svg {
    fill-opacity: 0.5;

    g {
      opacity: 0.5;
    }

    path {
      fill: #FFFFFF;
      fill-opacity: 0.5;
    }

  }
}

@media screen and (max-width: 1023px) {
  .sidenav {
    display: none;
  }
}