.layout {
  background-color: #090909;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .layout__video {
    width: 100%;
    min-height: 100%;
    object-fit: cover;

  }

  .layout__videoBlock {
    width: 100%;
  }

  .layout__videoMobileBlock {
    width: 100%;
    display: none;
  }

  .layout__meditation {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;

    .layout__meditationHeader {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 56px;
      line-height: 67px;
      color: #FFFFFF;
      margin: 0;
    }

    .layout__meditationContent {
      max-width: 800px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.5;
      margin: 16px 0;


    }

    .layout__meditationBtn {
      cursor: pointer;
      background: linear-gradient(132.51deg, #15DFEC -19.92%, #1DF533 126.64%);
      border: 0.5px solid #199F52;
      border-radius: 20px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #0A0A0A;
      padding: 11px 12px;
      display: flex;
      align-items: center;
      text-align: center;

    }

    .layout__meditationQR {
      width: 200px;
      min-height: 200px;
      background: url("/assets/img/layout/QRMeditation.svg") no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 20px;

      &:after {
        content: 'Отсканируйте QR-код, чтобы скачать приложение.';
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        opacity: 0.3;
        margin: 0 0 0 0;
        max-width: 200px;
        display: flex;
        align-items: center;
        text-align: center;
      }

    }

  }

  .layout__aboutProject {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin-top: 2px;

    .layout__aboutProjectBtn {
      white-space: nowrap;
      cursor: pointer;
      margin-right: 28px;
      padding: 16px 24px;
      gap: 8px;
      width: 213px;
      height: 48px;
      border-radius: 30px;
      border: 0px;
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #FFFFFF;
      background: url('/assets/img/layout/btnBackground.png') center no-repeat;

      &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), url('/assets/img/layout/btnBackground.png') center no-repeat;
      }
    }
  }
}

.layout__openMindsBlock {
  margin-top: 50px;
}


.layout__aboutProjectImg {
  min-height: 100%;
  width: 100%;
}

.layout__aboutProjectIcon {
  fill-opacity: 0.5;

  &:hover {
    cursor: pointer;

    path {
      fill: #FFFFFF;
      fill-opacity: 1;
    }

  }
}

.layout__aboutProjectBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}


.layout__aboutProjectFooter {
  fill-opacity: 0.5;

  &:hover {
    cursor: pointer;

    path {
      fill: #FFFFFF;
      fill-opacity: 1;
    }

  }

  display: flex;
  align-items: center;
  margin-bottom: 40px;

  &:hover {
    color: #fff;
    opacity: 1;
  }
}

.layout__aboutProjectBottomWatch {
  max-width: 225px;
  max-height: 270px;
  padding-right: 10px;
  padding-top: 33px;
  width: 95%;
}

.layout__aboutProjectBottomAvatars {
  padding-top: 71px;
  width: 79%;
}

.layout__fitnessTrackerLink {
  text-decoration: none;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.4;
  margin-left: 15px;

  &:hover {
    color: #FFFFFF;
    opacity: 0.8;
  }
}

.layout__openMindsLink {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: url("/assets/img/layout/btnBackground.png") center no-repeat, linear-gradient(138.84deg, #15DFEC -12.58%, #1DF533 125.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), url('/assets/img/layout/btnBackground.png') center no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.layout__downloadApp {
  margin-top: 10px;
  height: 795px;
  display: flex;
  width: 90%;
  flex-direction: row;
  background: url('/assets/img/layout/cardMobileIcon.png') center no-repeat;

  h3 {
    margin: 37px 0 10px 0;
  }

  p {
    margin: 0 0 20px 0;
  }
}

.layout__downloadAppLeftSection {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
  margin-top: 60px;
}

.layout__downloadAppHeader {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 67px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  width: 300px;

}

.layout__downloadAppContent {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  opacity: 0.5;
  max-width: 63%;
}

.layout__downloadAppBtn {
  padding: 11px 12px 10px;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0A0A0A;
  background: linear-gradient(262.37deg, #65B5FF 38.73%, #64E4FF 92.89%);
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 36px;
}


.layout__downloadAppImg {
  width: 200px;
  min-height: 280px;
  background: url("/assets/img/layout/QRMeditation.svg") center no-repeat;
  background-position: -50px;
  background-size: cover;
  display: flex;
  flex-direction: column-reverse;


  &:after {
    content: 'Наведи камеру мобильного телефона и отсканируй QR-код, чтобы скачать приложение';
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    opacity: 0.3;
    margin: 0px 0 -5px 0;
    max-width: 190px;
    display: flex;
    align-items: center;
  }

}

.layout__downloadAppMobile {
  width: 70%;
  height: 443px;
}

.layout__whitePaper {
  background: url('/assets/img/layout/whitePaperBackground.png') center no-repeat;
  height: 340px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.layout__whitePaperHeader {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 67px;
  color: #FFFFFF;
  margin: 100px 0 16px 0;
}

.layout__whitePaperContent {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.5;
  margin: 0 0 16px 0;

}

.layout__footerDownload {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 440px;
}

.layout__footerDownloadHeader {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 67px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.layout__aboutProjectImgMedium {
  width: 100%;
  min-height: 100%;
  display: none;
}

.layout__footerDownloadContent {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.5;
}

.layout__footerDownloadBtn {
  padding: 14px 20px;
  gap: 10px;
  width: 150px;
  height: 54px;
  background: #FFFFFF;
  border-radius: 30px;
  cursor: pointer;
  display: none;
}

.layout__DownloadBtn {
  padding: 14px 20px;
  gap: 10px;
  width: 150px;
  height: 54px;
  background: #FFFFFF;
  border-radius: 30px;
  cursor: pointer;
  display: none;
}

.layout__aboutProjectContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.layout__aboutEYWA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 868px;

  .layout__aboutEYWAHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 67px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 16px;
  }

  .layout__aboutEYWAContent {
    margin-top: 0;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.5;
    max-width: 83%;
  }
}

.layout__aboutEYWACard {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: start;
}

.layout__aboutEYWACardMeditation {
  background: url("/assets/img/layout/meditationBackground.png") center no-repeat;
  background-size: cover;
  height: 244px;
  width: 49%;
  border-radius: 32px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.layout__aboutEYWACardMentalHealthy {
  background: url("/assets/img/layout/mentalHealthyBackground.png") center no-repeat;
  background-size: cover;
  height: 244px;
  width: 49%;
  border-radius: 32px;
}

.layout__aboutEYWACardYoga {
  background: url("/assets/img/layout/yogaBackground.png") center no-repeat;
  background-size: cover;
  height: 244px;
  width: 49%;
  border-radius: 32px;
  margin-right: 16px;
}

.layout__aboutEYWACardPeopleDesign {
  background: url("/assets/img/layout/peopleDesignBackground.png") center no-repeat;
  background-size: cover;
  height: 244px;
  width: 49%;
  border-radius: 32px;
}

.layout__stepsToBalance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 514px;
}

.layout__stepsToBalanceHeader {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 67px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-top: 0;
}

.layout__stepsToBalanceContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: url("/assets/img/layout/stepsToBalanceBackground.png") center no-repeat;
  background-size: cover;
  height: 263px;
  width: 100%;
  border-radius: 32px;
}


#roadmap {
  max-width: 880px !important;
}

@media screen and (max-width: 1440px) {
  .layout {
    max-width: 1440px;
  }

}

@media screen and (max-width: 1024px) {


  .layout__aboutProjectContent {
    flex-direction: column;
    align-items: flex-start;
  }
  .layout__aboutProjectBtn {
    margin-bottom: 20px;
    margin-right: 0 !important;
  }

  .layout__aboutProjectImgMedium {
    display: block;
  }

  .layout__downloadAppMobile {

  }

  .layout__downloadAppContent {
    max-width: 648px;

  }


  .layout__aboutProjectBottom {
    flex-direction: column;
    margin: 20px 0;
  }

  .layout__aboutProjectImg {
    width: 130%;
    display: none;
  }

  .layout__aboutProjectBottomAvatars {
    width: 65%;
  }
  .layout__aboutProjectBottomWatch {
    padding-top: 20px;
    width: 85%;

  }
  .layout__downloadAppMobile {
    display: block;
  }

  .layout__aboutProjectContent {
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  .layout__downloadApp {
    width: 100%;
  }
  .layout__downloadAppLeftSection {
    margin-left: 57px;
  }
}

@media screen and (max-width: 1023px) {

  .layout__stepsToBalanceContent {
    flex-direction: column;
    height: 416px;
  }

  .layout__stepsToBalanceHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;

  }

  .layout__aboutEYWACard {
    flex-shrink: 1;
  }

  .layout__aboutEYWACardMeditation {
    width: 49%;
    border-radius: 32px;
    margin-right: 12px;
    margin-bottom: 12px;
  }

  .layout__aboutEYWACardMentalHealthy {
    width: 49%;
    border-radius: 32px;
  }

  .layout__aboutEYWACardYoga {
    width: 49%;
    border-radius: 32px;
    margin-right: 12px;
  }

  .layout__aboutEYWACardPeopleDesign {
    width: 49%;
    border-radius: 32px;
  }


  .layout__aboutEYWA {

    .layout__aboutEYWAHeader {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 38px;
      line-height: 45px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
    }

    .layout__aboutEYWAContent {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.5;
    }
  }

  .layout__downloadAppMobile {
    display: none;
  }
  .layout__downloadApp {
    margin-top: 40px;
    background: none;
  }

  .layout__downloadAppLeftSection {
    width: 100%;
    margin-left: 0;
  }
  .layout__downloadAppHeader {
    width: 100%;
    justify-content: center;
  }


  .layout__downloadAppImg {
    margin-top: 30px;
    background: url("/assets/img/layout/QRMeditation.svg") center no-repeat;
    min-height: 220px;
    width: 500px;
    flex-direction: column-reverse;

    &:after {
      content: 'Наведи камеру мобильного телефона и отсканируй QR-код, чтобы скачать приложение';
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      opacity: 0.3;
      margin: 0 0 -48px 155px;
      max-width: 190px;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }

  .layout__downloadAppLeftSection {
    align-items: center;
  }

  .layout__downloadAppContent {
    text-align: center;
  }
  .layout__downloadApp {
    align-items: center;
    width: auto;
  }
  .layout__downloadAppLeftSection {
    align-items: center;
  }
}

@media screen and (max-width: 768px) {


  .layout {


    .layout__aboutProjectBottomWatch {
      display: block;
      width: 100%;
    }

    .layout__aboutProjectBottomAvatars {
      display: block;

    }

  }
  .layout__footerDownloadBtn {
    display: none;
  }
  .layout__downloadAppImg {
    height: 200px;
  }


  .layout__aboutProjectBtn {
    margin-right: 0 !important;
    margin-bottom: 10px;
  }

  .layout__aboutProjectContent {
    flex-direction: column;

  }

  .layout__downloadAppLeftSection {
    display: flex;
    align-items: center;
    flex-direction: column;
  }


  .layout {
    .layout__downloadAppMobile {
      display: none !important;
    }

    .layout__meditationQR {
      display: block;
    }

    .layout__meditationQRContent {
      display: block;
    }

    .layout__meditation {

      .layout__meditationHeader {
        font-size: 38px;
        margin-bottom: 0;
      }

      .layout__meditationContent {
        max-width: 648px;
        min-height: 66px;
        font-family: 'Helvetica Neue';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        opacity: 0.5;
        margin-top: 0;
        padding: 0 5px;
      }

    }
  }
  .layout__aboutProjectBtn {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
  }
  .layout__downloadAppHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 45px;
    color: #FFFFFF;
    padding-left: 0;
    display: flex;
    align-items: center;
    text-align: center;

  }
  .layout__downloadAppContent {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 0.5;
    display: flex;
    align-items: center;
    text-align: center;


  }
  .layout__whitePaperHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 45px;
    color: #FFFFFF;
  }
  .layout__whitePaperContent {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 0.5;
  }
  .layout__footerDownload {
    display: none;
  }
  .layout__DownloadBtn {
    display: none;
  }
  .layout__downloadAppMobile {
    display: none;
  }
  .layout__videoBlock {
    height: 500px;
  }
  .layout__videoMobileBlock {
    height: 500px;
  }
}

@media screen and (max-width: 767px) {
  .layout__aboutEYWA {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 1172px;

    .layout__aboutEYWAHeader {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 38px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;

    }

    .layout__aboutEYWAContent {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 125%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.5;
      max-width: 90%;
    }
  }

  .layout__aboutEYWACard {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: start;
  }

  .layout__aboutEYWACardMeditation {
    background: url("/assets/img/layout/meditationBackground.png") center no-repeat;
    background-size: cover;
    height: 200px;
    width: 100%;
    border-radius: 32px;
    margin-bottom: 16px;
  }

  .layout__aboutEYWACardMentalHealthy {
    background: url("/assets/img/layout/mentalHealthyBackground.png") center no-repeat;
    background-size: cover;
    height: 200px;
    width: 100%;
    border-radius: 32px;
    margin-bottom: 16px;
  }

  .layout__aboutEYWACardYoga {
    background: url("/assets/img/layout/yogaBackground.png") center no-repeat;
    background-size: cover;
    height: 200px;
    width: 100%;
    border-radius: 32px;
    margin-bottom: 16px;
  }

  .layout__aboutEYWACardPeopleDesign {
    background: url("/assets/img/layout/peopleDesignBackground.png") center no-repeat;
    background-size: cover;
    height: 200px;
    width: 100%;
    border-radius: 32px;
  }

  .layout__stepsToBalance {

    height: 600px;
  }

  .layout__stepsToBalanceHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  .layout__stepsToBalanceContent {
    //display: flex;
    //flex-direction: row;
    //justify-content: flex-start;
    //align-items: center;
    //background: url("/assets/img/layout/stepsToBalanceBackground.png") center no-repeat;
    //background-size: cover;
    //height: 263px;
    //width: 100%;
    //border-radius: 32px;
  }

  .layout__videoBlock {
    display: none;
  }
  .layout__videoMobileBlock {
    display: block !important;
  }
  .layout__whitePaper {
    background: url('/assets/img/layout/whitePaperBackground.webp') center no-repeat;
  }

  .layout__aboutProjectImgMedium {
    display: none;
  }
  .layout__aboutProjectContent {
    align-items: flex-start;
  }
  .layout__footerDownloadBtn {
    display: block;
    margin-bottom: 48px;
    margin-right: 10px;
  }
  .layout__downloadAppImg {
    display: none !important;
  }
  .layout {
    .layout__meditation {
      .layout__meditationQR {
        display: none;
      }

      .layout__meditationQRContent {
        display: none;
      }
    }
  }
  .layout__aboutProjectImg {
    display: none;
  }
  .layout {
    .layout__meditation {
      margin-top: 36px;

      .layout__meditationHeader {
        font-size: 28px;
      }

      .layout__meditationContent {
        font-size: 16px;
      }

      .layout__meditationBtn {
        font-size: 11px;
        margin-bottom: 26px;
      }
    }

    .layout__aboutProjectBottomWatch {
      display: none !important;
    }

    .layout__aboutProjectBottomAvatars {
      display: none !important;
    }

    .layout__aboutProjectImg {
      display: none !important;
    }
  }
  .layout__downloadAppHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 45px;
    color: #FFFFFF;
    padding-left: 0;
  }
  .layout__downloadAppContent {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 0.5;

  }
  .layout__whitePaperHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 45px;
    color: #FFFFFF;
  }
  .layout__whitePaperContent {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 0.5;
  }
}

@media screen and (max-width: 550px) {
  .layout__openMindsBlock {
    margin-top: 0;
  }
  .layout__aboutProjectBottomWatch {
    display: none !important;
  }
  .layout__aboutProjectBottomAvatars {
    display: none !important;
  }

  .layout__aboutProjectImg {
    display: none !important;
  }

}

@media screen and (max-width: 375px) {
  .layout__downloadAppContent {
    max-width: 335px;
    max-height: 60px;
  }
  .layout__aboutProjectBtn {
    margin-bottom: 24px;
  }
  .layout__aboutProjectContent {
    flex-direction: column;
  }
  .layout__aboutProjectBottom {
    flex-direction: column;
  }
  .layout__downloadAppLeftSection {
    align-items: center;
  }
  .layout__downloadAppMobile {
    display: none;
  }
  .layout {
    .layout__meditation {
      margin-top: 0;

      .layout__meditationHeader {
        font-size: 20px;
      }

      .layout__meditationQR {
        display: none;
      }

      .layout__meditationQRContent {
        display: none;
      }
    }

    .layout__aboutProjectImg {
      display: none;
    }
  }

  .layout__aboutProject {
    margin-top: 50px;
  }
  .layout__aboutProjectContent {
    align-items: flex-start;
  }
  .layout__aboutProjectBottomWatch {
    display: none;
  }
  .layout__aboutProjectBottomAvatars {
    display: none;
  }
  .layout__DownloadBtn {
    display: block;
    padding: 0;
    width: 125px;

  }
  .layout__downloadAppHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 45px;
    color: #FFFFFF;
    padding-left: 0;
  }
  .layout__downloadAppContent {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 0.5;
    padding: 0;
  }

  .layout__whitePaperHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 38px;
    color: #FFFFFF;
  }

  .layout__whitePaperContent {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    opacity: 0.5;
  }
  .layout__downloadAppBtn {
    margin: 0;
  }
  .layout__downloadAppImg {
    display: none;
  }
}