.card__header {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  color: #FFFFFF;
  margin: 0 0 0 20px;
}

.card__img {
  width: 48px;
  height: 48px;
}

.card__content {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 130%;
  color: #FFFFFF;
  opacity: 0.5;
  margin-bottom: 0;
  margin-top: 24px;
  width: 80%;
  margin-left: 40px;
}

.card__headerContent {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 40px;
  margin-left: 40px;
}

@media screen and (max-width: 1023px) {
  .card__header {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .card__headerContent {
    margin-left: 24px;
    margin-top: 24px;
  }
  .card__content {
    margin-left: 24px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    opacity: 0.5;
  }
}

@media screen and (max-width: 767px) {
  .card__img {
    width: 32px;
    height: 32px;
  }
  .card__content {
    width: 90%;
  }
}

