.btn__border {
  padding: 10px 14px;
  gap: 8px;
  border-radius: 30px;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  background-color: #090909;
  color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;

  &:hover {
    background-color: #fff;
    color: #090909;
  }
}

.btn__noneBorder {
  background-color: #090909;
  color: white;
  padding: 10px 14px;
  gap: 8px;
  border-radius: 30px;
  border: 0;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;

  &:hover {
    background-color: #fff;
    color: #090909;
  }
}

.btn__borderLink {
  padding: 10px 14px;
  gap: 8px;
  border-radius: 30px;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  background-color: #090909;
  color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;

  &:hover {
    background-color: #fff;
    color: #090909;
  }
}

.btn__noneBorderLink {
  background-color: #090909;
  color: white;
  padding: 10px 14px;
  gap: 8px;
  border-radius: 30px;
  border: 0;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;

  &:hover {
    background-color: #fff;
    color: #090909;
  }
}


@media screen and (max-width: 768px) {
  .btn__noneBorder {
    font-size: 13px;

  }
  .btn__border {
    font-size: 13px;

  }

  .btn__noneBorderLink {
    font-size: 13px;

  }
  .btn__borderLink {
    font-size: 13px;

  }

}


@media screen and (max-width: 375px) {
  .btn__noneBorder {
    font-size: 13px;

  }
}